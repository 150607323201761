import { MERGE_TAGS } from "@platform-app/app/agreement-builder/shared/constants";
import {
  CurrentAgreementModel,
  EditorToolbarMode,
} from "@platform-app/app/agreement-builder/shared/models";
import { AffiliationAgreementSubStatus } from "@platform-app/app/core/api/models";

// Tiny Editor Configuration Constants
export const PLUGINS = [
  "powerpaste",
  "casechange",
  "importcss",
  "searchreplace",
  "autolink",
  "directionality",
  "advcode",
  "visualblocks",
  "visualchars",
  "fullscreen",
  "image",
  "link",
  "media",
  "mediaembed",
  "codesample",
  "charmap",
  "pagebreak",
  "nonbreaking",
  "anchor",
  "tableofcontents",
  "insertdatetime",
  "advlist",
  "lists",
  "checklist",
  "wordcount",
  "tinymcespellchecker",
  "editimage",
  "help",
  "formatpainter",
  "permanentpen",
  "pageembed",
  "charmap",
  "mentions",
  "linkchecker",
  "emoticons",
  "footnotes",
  "mergetags",
  "autocorrect",
  "typography",
  "advtemplate",
  "markdown",
  "autoresize",
  "quickbars",
];

export const EDITOR_TOOLBAR_CREATE =
  "undo redo | blocks | fontfamily | fontsizeinput | bold italic underline | mergetags | importword downloadpdf | align lineheight numlist bullist outdent indent ";

export const EDITOR_TOOLBAR_COMMENT = "addcomment | downloadpdf";

export const EDITOR_TOOLBAR_EMPTY = "downloadpdf";

export const TYPOGRAPHY_RULES = [
  "common/punctuation/quote",
  "en-US/dash/main",
  "common/nbsp/afterParagraphMark",
  "common/nbsp/afterSectionMark",
  "common/nbsp/afterShortWord",
  "common/nbsp/beforeShortLastNumber",
  "common/nbsp/beforeShortLastWord",
  "common/nbsp/dpi",
  "common/punctuation/apostrophe",
  "common/space/delBeforePunctuation",
  "common/space/afterComma",
  "common/space/afterColon",
  "common/space/afterExclamationMark",
  "common/space/afterQuestionMark",
  "common/space/afterSemicolon",
  "common/space/beforeBracket",
  "common/space/bracket",
  "common/space/delBeforeDot",
  "common/space/squareBracket",
  "common/number/mathSigns",
  "common/number/times",
  "common/number/fraction",
  "common/symbols/arrow",
  "common/symbols/cf",
  "common/symbols/copy",
  "common/punctuation/delDoublePunctuation",
  "common/punctuation/hellip",
];

export const CONTENT_STYLES = `
  body {
    font-size: 16px;
    font-family: "Times New Roman";
  }

  .mce-content-body {
    padding: 2rem 1.25rem !important;

    .mce-mergetag {
      font-weight: 600 !important;
      background-color: #eef0ff !important;
      cursor: pointer !important;
    }

    .tox-comment .mce-mergetag,
    .mce-signatory .tox-comment .mce-mergetag {
      background-color: transparent !important;
      cursor: auto !important;
    }

    .mce-mergetag-affix {
      background-color: #eef0ff !important;
    }

    [contentEditable="false"][data-mce-selected] {
      background-color: #cbd2ff !important;
      outline: none !important;

      .mce-mergetag-affix {
        background-color: #cbd2ff !important;
      }
    }

    .tox-comment {
      background-color: #fff281 !important;
      cursor: pointer !important;

      &[data-mce-annotation-active] {
        background-color: #f0d800 !important;
      }
    }

    .mce-signatory:has(.tox-comment),
    .mce-signatory:has(.tox-comment):hover,
    .mce-signatory:has(.tox-comment)[data-mce-selected],
    .mce-signatory:has(.tox-comment)[data-mce-selected]:hover {
      background-color: #fff281 !important;

      .mce-signatory-item u {
        color: #686b8e !important;
      }
    }

    .mce-signatory:has(.tox-comment[data-mce-annotation-active]),
    .mce-signatory:has(.tox-comment[data-mce-annotation-active]):hover,
    .mce-signatory:has(.tox-comment[data-mce-annotation-active])[data-mce-selected],
    .mce-signatory:has(.tox-comment[data-mce-annotation-active])[data-mce-selected]:hover {
      background-color: #f0d800 !important;

      .mce-signatory-item u {
        color: black !important;
      }
    }

    .mce-signatory:hover,
    .mce-signatory[data-mce-selected]:hover {
      background-color: #f5f5f5 !important;
      border-radius: 0.5rem important;
    }

    .mce-signatory[data-mce-selected] {
      background-color: transparent !important;
    }

    .mce-signatory .mce-mergetag {
      background-color: #EEEEEE !important;
      cursor: default !important;
    }

    .mce-signatory .mce-mergetag-affix {
      background-color: #EEEEEE !important;
      color: #757575 !important;
    }

    .mce-signatory .tox-comment,
    .mce-signatory span:has(.mce-signatory-item),
    .mce-signatory span:has(.mce-signatory-title) {
      display: flex;
      flex-direction: column;
      gap: 1.5rem !important;
    }
  }`;

export const AGREEMENT_BUILDER_ATTRIBUTES = {
  tagSelected: "data-mce-selected",
  annotationSelected: "data-mce-annotation-active",
  manualSelection: "manual",
  observed: "observed",
  editable: "contenteditable",
  annotation: "data-mce-annotation",
  annotationId: "data-mce-annotation-uid",
};

export const AGREEMENT_BUILDER_SELECTORS = {
  mergeTag: ".mce-mergetag",
  comment: "comment",
  signatoryItem: ".mce-signatory-item",
};

export const AGREEMENT_BUILDER_STYLE_CLASSES = {
  comment: "tox-comment",
};

export const AGREEMENT_BUILDER_SIGNATORY_PLACEHOLDER_ID =
  "SIGNATORY_PLACEHOLDER";

export const EDITOR_UI_CONFIG = {
  toolbar_sticky: true,
  toolbar_sticky_offset: 80,
  menubar: false,
  resize: false,
  statusbar: false,
  content_style: CONTENT_STYLES,
  mergetags_prefix: "[",
  mergetags_suffix: "]",
  powerpaste_word_import: "merge",
  powerpaste_html_import: "merge",
  powerpaste_googledocs_import: "merge",
};

export const CUSTOM_ICONS: Record<string, string> = {
  undo: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M318.08-220q-12.77 0-21.39-8.62-8.61-8.61-8.61-21.38t8.61-21.38q8.62-8.62 21.39-8.62h257.07q62.62 0 107.77-41.35 45.16-41.34 45.16-102.11 0-60.77-45.16-101.93-45.15-41.15-107.77-41.15H294.31l90.23 90.23q8.69 8.7 8.69 21.08 0 12.38-8.69 21.08-8.69 8.69-21.27 8.5-12.58-.2-20.88-8.5L205.31-571.23q-5.62-5.62-7.92-11.85-2.31-6.23-2.31-13.46t2.31-13.46q2.3-6.23 7.92-11.85l137.08-137.07q8.69-8.69 21.07-8.69 12.39 0 21.08 8.69 8.69 8.69 8.5 21.27-.19 12.57-8.5 20.88l-90.23 90.23h280.84q87.77 0 150.35 58.58t62.58 144.5q0 85.92-62.58 144.69Q662.92-220 575.15-220H318.08Z"/></svg>',
  redo: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M665.69-566.54H384.85q-62.62 0-107.77 41.15-45.16 41.16-45.16 101.93 0 60.77 45.16 102.11Q322.23-280 384.85-280h257.07q12.77 0 21.39 8.62 8.61 8.61 8.61 21.38t-8.61 21.38q-8.62 8.62-21.39 8.62H384.85q-87.77 0-150.35-58.77t-62.58-144.69q0-85.92 62.58-144.5t150.35-58.58h280.84l-90.23-90.23q-8.31-8.31-8.5-20.88-.19-12.58 8.5-21.27t21.08-8.69q12.38 0 21.07 8.69l137.08 137.07q5.62 5.62 7.92 11.85 2.31 6.23 2.31 13.46t-2.31 13.46q-2.3 6.23-7.92 11.85L617.61-434.15q-8.3 8.3-20.88 8.5-12.58.19-21.27-8.5-8.69-8.7-8.69-21.08 0-12.38 8.69-21.08l90.23-90.23Z"/></svg>',
  comment:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m241.54-260-80.08 80.07q-17.07 17.08-39.27 7.74Q100-181.54 100-205.85v-581.84Q100-818 121-839q21-21 51.31-21h615.38Q818-860 839-839q21 21 21 51.31v455.38Q860-302 839-281q-21 21-51.31 21H241.54ZM216-320h571.69q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v523.08L216-320Zm-56 0v-480 480Zm120-90h240q12.77 0 21.38-8.62Q550-427.23 550-440t-8.62-21.38Q532.77-470 520-470H280q-12.77 0-21.38 8.62Q250-452.77 250-440t8.62 21.38Q267.23-410 280-410Zm0-120h400q12.77 0 21.38-8.62Q710-547.23 710-560t-8.62-21.38Q692.77-590 680-590H280q-12.77 0-21.38 8.62Q250-572.77 250-560t8.62 21.38Q267.23-530 280-530Zm0-120h400q12.77 0 21.38-8.62Q710-667.23 710-680t-8.62-21.38Q692.77-710 680-710H280q-12.77 0-21.38 8.62Q250-692.77 250-680t8.62 21.38Q267.23-650 280-650Z"/></svg>',
  "comment-add":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M450-530v90q0 12.77 8.62 21.38Q467.23-410 480-410t21.38-8.62Q510-427.23 510-440v-90h90q12.77 0 21.38-8.62Q630-547.23 630-560t-8.62-21.38Q612.77-590 600-590h-90v-90q0-12.77-8.62-21.38Q492.77-710 480-710t-21.38 8.62Q450-692.77 450-680v90h-90q-12.77 0-21.38 8.62Q330-572.77 330-560t8.62 21.38Q347.23-530 360-530h90ZM241.54-260l-80.08 80.07q-17.07 17.08-39.27 7.74Q100-181.54 100-205.85v-581.84Q100-818 121-839q21-21 51.31-21h615.38Q818-860 839-839q21 21 21 51.31v455.38Q860-302 839-281q-21 21-51.31 21H241.54ZM216-320h571.69q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v523.08L216-320Zm-56 0v-480 480Z"/></svg>',
  "chevron-down":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M460.81-393.04 334.76-519.08q-2.6-2.61-4.1-5.83-1.5-3.21-1.5-6.89 0-7.35 4.97-12.78 4.97-5.42 13.1-5.42h265.54q8.13 0 13.1 5.48 4.97 5.47 4.97 12.77 0 1.83-5.61 12.67L499.19-393.04q-4.34 4.35-8.98 6.35-4.64 2-10.21 2-5.57 0-10.21-2-4.64-2-8.98-6.35Z"/></svg>',
  "chevron-up":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M347.23-410q-8.13 0-13.1-5.48-4.97-5.47-4.97-12.77 0-1.83 5.61-12.67l126.04-126.04q4.34-4.35 8.98-6.35 4.64-2 10.21-2 5.57 0 10.21 2 4.64 2 8.98 6.35l126.05 126.04q2.6 2.61 4.1 5.83 1.5 3.21 1.5 6.89 0 7.35-4.97 12.78-4.97 5.42-13.1 5.42H347.23Z"/></svg>',
  "chevron-left":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M519.08-334.77 393.04-460.81q-4.35-4.34-6.35-8.98-2-4.64-2-10.21 0-5.57 2-10.21 2-4.64 6.35-8.98l126.04-126.05q2.61-2.6 5.83-4.1 3.21-1.5 6.89-1.5 7.35 0 12.78 4.97 5.42 4.97 5.42 13.1v265.54q0 8.13-5.48 13.1-5.47 4.97-12.77 4.97-1.83 0-12.67-5.61Z"/></svg>',
  "chevron-right":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M428.2-329.16q-7.35 0-12.78-4.97-5.42-4.97-5.42-13.1v-265.54q0-8.13 5.48-13.1 5.47-4.97 12.77-4.97 1.83 0 12.67 5.61l126.04 126.04q4.35 4.34 6.35 8.98 2 4.64 2 10.21 0 5.57-2 10.21-2 4.64-6.35 8.98L440.92-334.76q-2.61 2.6-5.83 4.1-3.21 1.5-6.89 1.5Z"/></svg>',
  underline:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M243.85-155q-12.75 0-21.38-8.63-8.62-8.63-8.62-21.39 0-12.75 8.62-21.37 8.63-8.61 21.38-8.61h472.3q12.75 0 21.38 8.63 8.62 8.63 8.62 21.38 0 12.76-8.62 21.37-8.63 8.62-21.38 8.62h-472.3ZM480-298.85q-93.31 0-145.65-56.65Q282-412.15 282-507.31v-279.38q0-15.14 11.17-25.96 11.17-10.81 26.31-10.81 15.13 0 25.9 10.81 10.77 10.82 10.77 25.96v283.07q0 60.62 32.23 97.16T480-369.92q59.39 0 91.62-36.54 32.23-36.54 32.23-97.16v-283.07q0-15.14 11.17-25.96 11.17-10.81 26.31-10.81 15.13 0 25.9 10.81Q678-801.83 678-786.69v279.38q0 95.16-52.35 151.81-52.34 56.65-145.65 56.65Z"/></svg>',
  italic:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M251.92-215q-15.18 0-25.67-10.49-10.48-10.5-10.48-25.7 0-15.19 10.48-25.66 10.49-10.46 25.67-10.46h116.54l129.62-385.38H381.54q-15.18 0-25.67-10.5-10.48-10.49-10.48-25.69 0-15.2 10.48-25.66Q366.36-745 381.54-745h293.85q15.18 0 25.66 10.49 10.49 10.5 10.49 25.7 0 15.19-10.49 25.66-10.48 10.46-25.66 10.46H571.15L441.54-287.31h104.23q15.18 0 25.67 10.5 10.48 10.49 10.48 25.69 0 15.2-10.48 25.66Q560.95-215 545.77-215H251.92Z"/></svg>',
  bold: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M354.31-215q-25.31 0-43.04-17.73t-17.73-43.04v-408.46q0-25.31 17.73-43.04T354.31-745H488q61.15 0 110.58 38.08Q648-668.85 648-604.38q0 44.84-21.66 73.11-21.65 28.27-46.65 41.04 30.77 10.61 58.77 41.96 28 31.35 28 84.42 0 76.69-56.54 112.77T496-215H354.31Zm25.23-79.69h113.23q47.23 0 66.77-26.23t19.54-50.31q0-24.08-19.54-50.31-19.54-26.23-68.62-26.23H379.54v153.08Zm0-230.31h103.77q36.46 0 57.81-20.85 21.34-20.84 21.34-49.92 0-30.92-22.57-50.54-22.58-19.61-55.35-19.61h-105V-525Z"/></svg>',
  indent:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Zm320-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-355 490-355h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-295 790-295H490Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-510 490-510h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H490Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-665 490-665h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-605 790-605H490ZM170-760q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Zm.92 385.23q-8.84 8.85-19.88 4.41Q140-374.8 140-387.23v-185.54q0-12.43 11.04-16.87 11.04-4.44 19.88 4.41l92.39 92.38q5.61 5.48 5.61 12.78 0 7.3-5.61 12.92l-92.39 92.38Z"/></svg>',
  outdent:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Zm320-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-355 490-355h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-295 790-295H490Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-510 490-510h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H490Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q477.25-665 490-665h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-605 790-605H490ZM170-760q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Zm75.23 385.23-92.38-92.38q-5.62-5.48-5.62-12.78 0-7.3 5.62-12.92l92.38-92.38q8.85-8.85 19.89-4.41 11.03 4.44 11.03 16.87v185.54q0 12.43-11.03 16.87-11.04 4.44-19.89-4.41Z"/></svg>',
  "align-left":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-355 170-355h380q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q562.75-295 550-295H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-510 170-510h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-665 170-665h380q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q562.75-605 550-605H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Z"/></svg>',
  "align-center":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Zm160-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q317.25-355 330-355h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q642.75-295 630-295H330ZM170-450q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-510 170-510h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H170Zm160-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q317.25-665 330-665h300q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q642.75-605 630-605H330ZM170-760q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Z"/></svg>',
  "align-right":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-760q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Zm240 155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q397.25-665 410-665h380q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-605 790-605H410ZM170-450q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-510 170-510h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H170Zm240 155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q397.25-355 410-355h380q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-295 790-295H410ZM170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Z"/></svg>',
  "align-justify":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M170-140q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-200 170-200h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-140 790-140H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-355 170-355h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-295 790-295H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-510 170-510h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-450 790-450H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-665 170-665h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-605 790-605H170Zm0-155q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q157.25-820 170-820h620q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q802.75-760 790-760H170Z"/></svg>',
  "line-height":
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m216.16-664.46-52.93 52.31q-8.31 8.3-20.58 8.5-12.26.19-21.66-9.2-8.6-8.61-8.6-21 0-12.38 8.69-21.07l99.63-99.64q5.75-5.75 12.03-8.05 6.29-2.31 13.47-2.31 7.18 0 13.41 2.31 6.23 2.3 11.97 8.05l99.64 99.64q8.31 8.31 8.81 20.57.5 12.27-8.89 21.66-8.61 8.61-20.69 8.92-12.07.31-21.38-8.38l-52.93-52.31v368.92l52.93-52.31q8.31-8.3 20.57-8.5 12.27-.19 21.66 9.2 8.61 8.61 8.61 21 0 12.38-8.69 21.07l-99.64 99.64q-5.74 5.75-12.03 8.05-6.28 2.31-13.46 2.31-7.18 0-13.41-2.31-6.23-2.3-11.98-8.05l-99.63-99.64q-8.31-8.31-8.81-20.57-.5-12.27 8.89-21.66 8.61-8.61 20.69-8.92 12.07-.31 21.38 8.38l52.93 52.31v-368.92ZM517.31-220q-12.39 0-21-8.63-8.62-8.63-8.62-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62h310.39q13.56 0 22.74 9.17 9.18 9.17 9.18 22.73t-9.58 22.95q-9.58 9.38-23.11 9.38l-310-4.23Zm.38-230q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62H830q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q842.75-450 830-450H517.69Zm0-230q-12.75 0-21.37-8.74-8.63-8.73-8.63-21.64 0-12.59 8.52-21.1 8.51-8.52 21.1-8.52l310.38-4.23q13.73 0 23.02 9.17t9.29 22.73q0 13.56-9.29 22.95-9.29 9.38-23.02 9.38h-310Z"/></svg>',
  addtag:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M596.54-780q17.17 0 32.54 7.58 15.36 7.58 25.3 20.96l172.7 227.69q14.84 19.46 14.84 43.77 0 24.31-14.84 43.77l-172.7 227.69q-9.94 13.38-25.3 20.96-15.37 7.58-32.54 7.58h-81.16q-12.75 0-21.37-8.63-8.62-8.63-8.62-21.38 0-12.76 8.62-21.37 8.62-8.62 21.37-8.62h81.16q3.08 0 5.58-1.15 2.5-1.16 4.42-3.47l172.69-227.69q2.69-3.46 2.69-7.69t-2.69-7.69L606.54-715.38q-1.92-2.31-4.42-3.47-2.5-1.15-5.58-1.15H172.31q-5.39 0-8.85 3.46t-3.46 8.85v150q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63-12.76 0-21.37-8.63-8.62-8.62-8.62-21.37v-150q0-29.83 21.24-51.07Q142.48-780 172.31-780h424.23ZM247.68-140q-12.76 0-21.37-8.63-8.62-8.62-8.62-21.37v-90h-90q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62h90v-90q0-12.75 8.63-21.38 8.63-8.62 21.39-8.62 12.75 0 21.37 8.62 8.61 8.63 8.61 21.38v90h90q12.75 0 21.38 8.63 8.62 8.63 8.62 21.38 0 12.76-8.62 21.37-8.63 8.62-21.38 8.62h-90v90q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63Zm223.47-340Z"/></svg>',
  save: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-343.54q-7.23 0-13.46-2.31-6.23-2.3-11.85-7.92L330.31-478.15q-8.92-8.93-8.81-20.89.12-11.96 8.81-21.27 9.31-9.3 21.38-9.61 12.08-.31 21.39 9L450-444v-306q0-12.77 8.62-21.38Q467.23-780 480-780t21.38 8.62Q510-762.77 510-750v306l76.92-76.92q8.93-8.92 21.19-8.81 12.27.12 21.58 9.42 8.69 9.31 9 21.08.31 11.77-9 21.08L505.31-353.77q-5.62 5.62-11.85 7.92-6.23 2.31-13.46 2.31ZM252.31-180Q222-180 201-201q-21-21-21-51.31v-78.46q0-12.77 8.62-21.38 8.61-8.62 21.38-8.62t21.38 8.62q8.62 8.61 8.62 21.38v78.46q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h455.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-78.46q0-12.77 8.62-21.38 8.61-8.62 21.38-8.62t21.38 8.62q8.62 8.61 8.62 21.38v78.46Q780-222 759-201q-21 21-51.31 21H252.31Z"/></svg>',
  upload:
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m450-516-76.92 76.92q-8.93 8.92-21.19 8.81-12.27-.12-21.58-9.42-8.69-9.31-9-21.08-.31-11.77 9-21.08l124.38-124.38q5.62-5.62 11.85-7.92 6.23-2.31 13.46-2.31t13.46 2.31q6.23 2.3 11.85 7.92l124.38 124.38q8.92 8.93 8.81 20.89-.12 11.96-8.81 21.27-9.31 9.3-21.38 9.61-12.08.31-21.39-9L510-516v306q0 12.77-8.62 21.38Q492.77-180 480-180t-21.38-8.62Q450-197.23 450-210v-306ZM180-629.23v-78.46Q180-738 201-759q21-21 51.31-21h455.38Q738-780 759-759q21 21 21 51.31v78.46q0 12.77-8.62 21.38-8.61 8.62-21.38 8.62t-21.38-8.62q-8.62-8.61-8.62-21.38v-78.46q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H252.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v78.46q0 12.77-8.62 21.38-8.61 8.62-21.38 8.62t-21.38-8.62q-8.62-8.61-8.62-21.38Z"/></svg>',
};

export class EditorEvents {
  static ComponentInitialized = "init";
  static NodeChanged = "NodeChange";
  static Input = "input";
  static MarkedAsDirty = "Dirty";
  static Paste = "paste";
}

// General Editor Configuration Constants
export const EDITOR_CONFIG = {
  plugins: PLUGINS,
  typography_rules: TYPOGRAPHY_RULES,
  mergetags_list: MERGE_TAGS,
  quickbars_insert_toolbar: false,
  contextmenu: false as const,
  ...EDITOR_UI_CONFIG,
};

export function getEditorToolbar(
  agreement: CurrentAgreementModel | null | undefined,
): string | boolean {
  const mode = getEditorToolbarMode(agreement?.subStatus ?? null);
  switch (mode) {
    case "create":
      return EDITOR_TOOLBAR_CREATE;
    case "comment":
      return EDITOR_TOOLBAR_COMMENT;
    case "empty":
      return EDITOR_TOOLBAR_EMPTY;
    default:
      return false;
  }
}

export function getEditorReadonly(
  agreementSubStatus: AffiliationAgreementSubStatus | null,
): boolean {
  if (!agreementSubStatus) return false;

  switch (agreementSubStatus) {
    case AffiliationAgreementSubStatus.Sent:
    case AffiliationAgreementSubStatus.AwaitingReview:
    case AffiliationAgreementSubStatus.ChangeRequestSubmitted:
    case AffiliationAgreementSubStatus.ChangesProvidedByCounterparty:
    case AffiliationAgreementSubStatus.ChangesProvidedByYou:
      return true;
    case AffiliationAgreementSubStatus.Draft:
    case AffiliationAgreementSubStatus.ChangeRequestReceived:
    case AffiliationAgreementSubStatus.RequestedChangesSavedByYou:
      return false;
    default:
      return true;
  }
}

function getEditorToolbarMode(
  agreementSubStatus: AffiliationAgreementSubStatus | null,
): EditorToolbarMode {
  if (!agreementSubStatus) return "create";

  switch (agreementSubStatus) {
    case AffiliationAgreementSubStatus.AwaitingReview:
    case AffiliationAgreementSubStatus.ChangeRequestSubmitted:
    case AffiliationAgreementSubStatus.ChangesProvidedByCounterparty:
      return "comment";
    case AffiliationAgreementSubStatus.Draft:
    case AffiliationAgreementSubStatus.ChangeRequestReceived:
    case AffiliationAgreementSubStatus.RequestedChangesSavedByYou:
      return "create";
    case AffiliationAgreementSubStatus.Sent:
    default:
      return "empty";
  }
}

export const EDITOR_CUSTOM_BUTTONS = {
  addcomment: { icon: "comment", text: "Add comment", tooltip: "Add comment" },
  downloadpdf: {
    icon: "save",
    tooltip: "Download PDF",
  },
  importword: {
    icon: "upload",
    text: "Import file",
    tooltip: "Import file",
  },
};
