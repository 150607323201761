import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  AcceptCurrentAgreement,
  AddAgreementTemplate,
  AgreementTemplateSelected,
  DeleteAgreementTemplate,
  EditorContentUpdated,
  GetCurrentAffiliationAgreement,
  LoadCurrentAgreementBuilderCommentThreads,
  LoadTemplateOptions,
  ProvideChangesForCurrentAgreement,
  RenameAgreementTemplate,
  RequestChangesForCurrentAgreement,
  ResetAgreementBuilderState,
  SaveAgreementAsDraft,
  SendPartnerInvitation,
  SetDraftCommentThreadId,
  SyncCurrentAgreementBuilderContentToBackend,
  UpdateAgreementField,
  UpdateEditorCommentThreadsIds,
  UpdateMergeTagsList,
  UpdateSelectedAgreementTemplateContent,
  CancelCurrentAgreement,
  SetConflictingDraftAgreements,
  SetInitialAgreementFields,
  SetImportedDocumentName,
} from "@platform-app/app/agreement-builder/agreement-builder.actions";
import {
  EDITOR_CONTENT_FILE_TYPE,
  MERGE_TAGS,
} from "@platform-app/app/agreement-builder/shared/constants";
import { EditorDynamicFieldsUtility } from "@platform-app/app/agreement-builder/shared/editor-dynamic-fields.utility";
import {
  AgreementBuilderTemplateModel,
  AgreementDynamicFieldsModel,
  CurrentAgreementModel,
  DynamicFieldUpdatedModel,
  MergeTagModel,
} from "@platform-app/app/agreement-builder/shared/models";
import { OnboardingTrackingService } from "@platform-app/app/core/analytics/onboarding-tracking.service";
import {
  AffiliationAgreement,
  AffiliationAgreementContact,
  AffiliationAgreementOrganization,
  AffiliationAgreementSource,
  ExternalPartnershipType,
  GetAffiliationAgreementCommentThreadsCommentThread,
  GetAffiliationAgreementTemplatesTemplate,
  GetMatchingAffiliationAgreementDraftsMatchingDraftAgreement,
  ProvideChangesForAffiliationAgreementAffiliationAgreementBody,
  SaveAffiliationAgreementAsDraftBody,
  SendAffiliationAgreementToPartnerBody,
  SendAffiliationAgreementToPartnerErrorCode,
} from "@platform-app/app/core/api/models";
import { AffiliationAgreementsService } from "@platform-app/app/core/api/services";
import { InvitePartnerTrackingService } from "@platform-app/app/main/shared/components/invite-partner-dialog/services/invite-partner-tracking.service";
import { BuiltInDisciplineId } from "@platform-app/app/main/shared/models";
import { DateUtility } from "@platform-app/app/main/shared/utilities/date.utility";
import { catchError, finalize, map, mergeMap, switchMap, tap } from "rxjs";

export interface AgreementBuilderStateModel {
  agreementDynamicFields: AgreementDynamicFieldsModel;
  currentAgreement: CurrentAgreementModel | null;
  lastUpdatedFieldModel: DynamicFieldUpdatedModel | null;
  mergeTags: MergeTagModel[];
  templateOptions: GetAffiliationAgreementTemplatesTemplate[];
  selectedTemplate: AgreementBuilderTemplateModel | null;
  builderContent: string | null;
  builderCommentThreads: GetAffiliationAgreementCommentThreadsCommentThread[];
  editorCommentThreadsIds: string[];
  draftCommentThreadId: string | null;
  sendInvitationLoading: boolean;
  saveTemplateContentLoading: boolean;
  renameTemplateLoading: boolean;
  negotiateChangesLoading: boolean;
  saveAgreementChangesLoading: boolean;
  acceptAgreementLoading: boolean;
  saveAsDraftLoading: boolean;
  isAvailableForNegotiation: boolean;
  conflictingDraftAgreements: GetMatchingAffiliationAgreementDraftsMatchingDraftAgreement[];
  invitePartnerErrors: {
    message: string;
    code?: SendAffiliationAgreementToPartnerErrorCode;
  }[];
  allowCounterpartyUpdate: boolean;
  importedDocumentName: string | null;
}

const defaultState: AgreementBuilderStateModel = {
  agreementDynamicFields: {
    agreementName: null,
    currentOrganizationName: null,
    counterpartyOrganization: { id: null, name: null, googlePlaceId: null },
    startDate: null,
    endDate: null,
    noEndDate: false,
    disciplines: null,
    applyForAnyDiscipline: false,
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhoneNumber: null,
  },
  currentAgreement: null,
  lastUpdatedFieldModel: null,
  mergeTags: MERGE_TAGS,
  templateOptions: [],
  selectedTemplate: null,
  builderContent: null,
  builderCommentThreads: [],
  editorCommentThreadsIds: [],
  draftCommentThreadId: null,
  sendInvitationLoading: false,
  saveTemplateContentLoading: false,
  renameTemplateLoading: false,
  negotiateChangesLoading: false,
  saveAgreementChangesLoading: false,
  acceptAgreementLoading: false,
  saveAsDraftLoading: false,
  isAvailableForNegotiation: false,
  conflictingDraftAgreements: [],
  invitePartnerErrors: [],
  allowCounterpartyUpdate: true,
  importedDocumentName: null,
};

@State<AgreementBuilderStateModel>({
  name: "AgreementBuilder",
  defaults: defaultState,
})
@Injectable()
export class AgreementBuilderState {
  private readonly affiliationAgreementsService = inject(
    AffiliationAgreementsService,
  );
  private readonly trackingService = inject(InvitePartnerTrackingService);
  private readonly onboardingTrackingService = inject(
    OnboardingTrackingService,
  );

  @Selector()
  static agreementDynamicFields(state: AgreementBuilderStateModel) {
    return state.agreementDynamicFields;
  }

  @Selector()
  static currentAgreement(state: AgreementBuilderStateModel) {
    return state.currentAgreement;
  }

  @Selector()
  static lastUpdatedField(state: AgreementBuilderStateModel) {
    return state.lastUpdatedFieldModel;
  }

  @Selector()
  static mergeTags(state: AgreementBuilderStateModel) {
    return state.mergeTags;
  }

  @Selector()
  static templateOptions(state: AgreementBuilderStateModel) {
    return state.templateOptions;
  }

  @Selector()
  static selectedTemplateId(state: AgreementBuilderStateModel) {
    return state.selectedTemplate?.id ?? null;
  }

  @Selector()
  static selectedTemplate(state: AgreementBuilderStateModel) {
    return state.selectedTemplate;
  }

  // TODO: Refactor and combine loadings during agreements reworking
  @Selector()
  static sendInvitationLoading(state: AgreementBuilderStateModel) {
    return state.sendInvitationLoading;
  }

  @Selector()
  static saveTemplateContentLoading(state: AgreementBuilderStateModel) {
    return state.saveTemplateContentLoading;
  }

  @Selector()
  static renameTemplateLoading(state: AgreementBuilderStateModel) {
    return state.renameTemplateLoading;
  }

  @Selector()
  static negotiateChangesLoading(state: AgreementBuilderStateModel) {
    return state.negotiateChangesLoading;
  }

  @Selector()
  static saveAgreementChangesLoading(state: AgreementBuilderStateModel) {
    return state.saveAgreementChangesLoading;
  }

  @Selector()
  static saveAsDraftLoading(state: AgreementBuilderStateModel) {
    return state.saveAsDraftLoading;
  }

  @Selector()
  static builderContent(state: AgreementBuilderStateModel) {
    return state.builderContent;
  }

  @Selector()
  static acceptAgreementLoading(state: AgreementBuilderStateModel) {
    return state.acceptAgreementLoading;
  }

  @Selector()
  static builderCommentThreads(state: AgreementBuilderStateModel) {
    const { editorCommentThreadsIds, builderCommentThreads } = state;

    const editorThreads = editorCommentThreadsIds
      .map((id) => builderCommentThreads.find((thread) => thread.id === id))
      .filter((thread) => thread !== undefined);

    const additionalThreads = builderCommentThreads.filter(
      (thread) => !editorCommentThreadsIds.includes(thread.id),
    );

    return [additionalThreads, editorThreads].flat();
  }

  @Selector()
  static draftCommentThreadId(state: AgreementBuilderStateModel) {
    return state.draftCommentThreadId;
  }

  @Selector()
  static isAvailableForNegotiation(state: AgreementBuilderStateModel) {
    return state.isAvailableForNegotiation;
  }

  @Selector()
  static conflictingDraftAgreements(state: AgreementBuilderStateModel) {
    return state.conflictingDraftAgreements;
  }

  @Selector()
  static invitePartnerErrors(state: AgreementBuilderStateModel) {
    return state.invitePartnerErrors;
  }

  @Selector()
  static allowCounterpartyUpdate(state: AgreementBuilderStateModel) {
    return state.allowCounterpartyUpdate;
  }

  @Selector()
  static importedDocumentName(state: AgreementBuilderStateModel) {
    return state.importedDocumentName;
  }

  @Action(UpdateAgreementField)
  updateAgreementField(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateAgreementField,
  ) {
    const stateSnapshot = ctx.getState();
    const { field, dynamic } = action.payload;

    ctx.patchState({
      agreementDynamicFields: {
        ...stateSnapshot.agreementDynamicFields,
        [field.id]: field.value,
      },
    });

    if (dynamic) {
      const tag = stateSnapshot.mergeTags.find(
        (t) => t.fieldId === field.id,
      )?.value;
      if (tag) {
        const value =
          EditorDynamicFieldsUtility.getStringFromFieldValue(field) ?? null;
        ctx.patchState({
          lastUpdatedFieldModel: {
            tag,
            value,
          },
        });
      }
    }
  }

  @Action(GetCurrentAffiliationAgreement)
  getCurrentAffiliationAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: GetCurrentAffiliationAgreement,
  ) {
    const { agreementId } = action.payload;

    return this.loadAgreementBuilderContent(agreementId, ctx);
  }

  @Action(EditorContentUpdated)
  setEditorContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: EditorContentUpdated,
  ) {
    ctx.patchState({
      builderContent: action.payload.content,
    });
  }

  @Action(UpdateMergeTagsList)
  updateMergeTagsList(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateMergeTagsList,
  ) {
    ctx.patchState({
      mergeTags: action.payload.tags,
    });
  }

  @Action(LoadTemplateOptions)
  loadTemplateOptions(ctx: StateContext<AgreementBuilderStateModel>) {
    return this.loadAgreementTemplateOptions(ctx);
  }

  @Action(AgreementTemplateSelected)
  agreementTemplateSelected(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AgreementTemplateSelected,
  ) {
    ctx.patchState({
      selectedTemplate: action.payload.template,
      importedDocumentName: null,
    });
  }

  @Action(AddAgreementTemplate)
  addAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AddAgreementTemplate,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { agreementDynamicFields: agreement } = ctx.getState();

    if (!templateName && !agreement.agreementName)
      throw new Error("Template name is missing. Template cannot be saved.");

    const name =
      templateName ??
      this.generateUniqueTemplateName(agreement.agreementName!, ctx);

    const htmlFile = this.getBuilderContentHtmlFile(name, ctx, true);

    return this.affiliationAgreementsService
      .addAffiliationAgreementTemplate({
        body: { Body: { templateName: name }, File: htmlFile },
      })
      .pipe(
        switchMap((response) =>
          this.loadAgreementTemplateOptions(ctx).pipe(
            tap(() =>
              ctx.patchState({
                selectedTemplate: { id: response.addedTemplateId, name },
              }),
            ),
          ),
        ),
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(UpdateSelectedAgreementTemplateContent)
  updateSelectedAgreementTemplateContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateSelectedAgreementTemplateContent,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { selectedTemplate } = ctx.getState();

    if (!selectedTemplate) throw new Error("Template cannot be saved.");

    const htmlFile = this.getBuilderContentHtmlFile(templateName, ctx, true);

    return this.affiliationAgreementsService
      .updateAffiliationAgreementTemplateContent({
        templateId: selectedTemplate.id,
        body: { content: htmlFile },
      })
      .pipe(
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(RenameAgreementTemplate)
  renameAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: RenameAgreementTemplate,
  ) {
    ctx.patchState({ renameTemplateLoading: true });

    const { templateId, templateName } = action.payload;

    return this.affiliationAgreementsService
      .renameAffiliationAgreementTemplate({
        templateId,
        body: { templateName },
      })
      .pipe(
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
        finalize(() => ctx.patchState({ renameTemplateLoading: false })),
      );
  }

  @Action(DeleteAgreementTemplate)
  deleteAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: DeleteAgreementTemplate,
  ) {
    const { templateId } = action.payload;
    const { selectedTemplate } = ctx.getState();

    return this.affiliationAgreementsService
      .deleteAffiliationAgreementTemplate({ templateId })
      .pipe(
        tap(() => {
          if (selectedTemplate?.id === templateId)
            ctx.patchState({ selectedTemplate: null });
        }),
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
      );
  }

  @Action(SendPartnerInvitation)
  sendPartnerInvitation(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SendPartnerInvitation,
  ) {
    ctx.patchState({ sendInvitationLoading: true });

    const { selectedTemplate, agreementDynamicFields, currentAgreement } =
      ctx.getState();

    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    const jsonBody = {
      agreement: this.getAffiliationAgreementBody(
        currentAgreement?.id ?? null,
        agreementDynamicFields,
      ),
      contact: this.getContactBody(
        agreementDynamicFields,
        action.payload.invitationMessage,
      ),
      source: AffiliationAgreementSource.Builder,
      builderContentTemplateId: selectedTemplate?.id ?? null,
    } satisfies SendAffiliationAgreementToPartnerBody;

    const body = { Body: jsonBody, Files: [htmlFile] };

    return this.affiliationAgreementsService
      .sendAffiliationAgreementToPartner({ body })
      .pipe(
        switchMap((response) =>
          this.onboardingTrackingService
            .trackInvitePartnerStepCompleted()
            .pipe(map(() => response)),
        ),
        mergeMap((response) =>
          this.trackingService.trackPartnerInvitation(
            jsonBody.agreement,
            !response.invitationSent,
            AffiliationAgreementSource.Builder,
          ),
        ),
        catchError((response) => {
          ctx.patchState({ invitePartnerErrors: response.error.errors });
          throw response.error.errors;
        }),
        finalize(() => ctx.patchState({ sendInvitationLoading: false })),
      );
  }

  @Action(LoadCurrentAgreementBuilderCommentThreads)
  loadAgreementBuilderCommentThreads(
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    const { id } = ctx.getState().currentAgreement!;

    return this.affiliationAgreementsService
      .getAffiliationAgreementCommentThreads({
        id,
      })
      .pipe(
        tap((response) =>
          ctx.patchState({
            builderCommentThreads: response.commentThreads,
            isAvailableForNegotiation:
              response.isAgreementAvailableForNegotiation,
          }),
        ),
      );
  }

  @Action(UpdateEditorCommentThreadsIds)
  updateEditorCommentThreadsIds(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateEditorCommentThreadsIds,
  ) {
    ctx.patchState({ editorCommentThreadsIds: action.payload.ids });
  }

  @Action(SetDraftCommentThreadId)
  setDraftCommentThreadId(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SetDraftCommentThreadId,
  ) {
    ctx.patchState({ draftCommentThreadId: action.payload.commentId });
  }

  @Action(SyncCurrentAgreementBuilderContentToBackend)
  syncBuilderContentToBackend(ctx: StateContext<AgreementBuilderStateModel>) {
    const { id, name } = ctx.getState().currentAgreement!;

    const htmlFile = this.getBuilderContentHtmlFile(name, ctx);

    return this.affiliationAgreementsService.updateAffiliationAgreementBuilderContent(
      {
        id,
        body: { file: htmlFile },
      },
    );
  }

  @Action(RequestChangesForCurrentAgreement)
  requestChangesForAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: RequestChangesForCurrentAgreement,
  ) {
    ctx.patchState({ negotiateChangesLoading: true });

    const { id } = ctx.getState().currentAgreement!;
    const { message } = action.payload;

    return this.affiliationAgreementsService
      .requestChangesForAffiliationAgreement({
        id,
        body: { message },
      })
      .pipe(finalize(() => ctx.patchState({ negotiateChangesLoading: false })));
  }

  @Action(ProvideChangesForCurrentAgreement)
  provideChangesForAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: ProvideChangesForCurrentAgreement,
  ) {
    const { comment, submitChanges } = action.payload;
    const { agreementDynamicFields } = ctx.getState();
    const { id } = ctx.getState().currentAgreement!;

    ctx.patchState({
      negotiateChangesLoading: submitChanges,
      saveAgreementChangesLoading: !submitChanges,
    });

    const jsonBody = this.getUpdatedAffiliationAgreementBody(
      agreementDynamicFields,
      comment,
      submitChanges,
    );
    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    const body = { Body: jsonBody, File: htmlFile };

    return this.affiliationAgreementsService
      .provideChangesForAffiliationAgreement({
        id,
        body,
      })
      .pipe(
        finalize(() =>
          ctx.patchState({
            negotiateChangesLoading: false,
            saveAgreementChangesLoading: false,
          }),
        ),
      );
  }

  @Action(AcceptCurrentAgreement)
  acceptCurrentAgreement(ctx: StateContext<AgreementBuilderStateModel>) {
    const { currentAgreement } = ctx.getState();

    if (!currentAgreement) throw new Error("Current agreement is not set.");

    ctx.patchState({
      acceptAgreementLoading: true,
    });

    const htmlFile = this.getBuilderContentHtmlFile(currentAgreement.name, ctx);

    const body = { Body: { acceptWithBuilder: true }, Files: [htmlFile] };

    return this.affiliationAgreementsService
      .acceptAffiliationAgreement({
        id: currentAgreement.id,
        body,
      })
      .pipe(
        finalize(() =>
          ctx.patchState({
            acceptAgreementLoading: false,
          }),
        ),
      );
  }

  @Action(ResetAgreementBuilderState)
  resetAgreementBuilderState(ctx: StateContext<AgreementBuilderStateModel>) {
    ctx.setState(defaultState);
  }

  @Action(SetConflictingDraftAgreements)
  setExistingDraftAgreement(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SetConflictingDraftAgreements,
  ) {
    ctx.patchState({ conflictingDraftAgreements: action.payload.agreements });
  }

  @Action(SaveAgreementAsDraft)
  saveAgreementAsDraft(ctx: StateContext<AgreementBuilderStateModel>) {
    ctx.patchState({ saveAsDraftLoading: true });

    const {
      selectedTemplate,
      agreementDynamicFields,
      currentAgreement,
      conflictingDraftAgreements,
    } = ctx.getState();

    const htmlFile = this.getBuilderContentHtmlFile(
      agreementDynamicFields.agreementName!,
      ctx,
    );

    const body = {
      Body: {
        agreement: this.getAffiliationAgreementBody(
          currentAgreement?.id ?? null,
          agreementDynamicFields,
        ),
        contact: this.getContactBody(agreementDynamicFields, null),
        source: AffiliationAgreementSource.Builder,
        templateId: selectedTemplate?.id ?? null,
        replaceDraftAgreementsIds: conflictingDraftAgreements.map((a) => a.id),
      } satisfies SaveAffiliationAgreementAsDraftBody,
      Files: [htmlFile],
    };

    return this.affiliationAgreementsService
      .saveAffiliationAgreementAsDraft({
        body,
      })
      .pipe(finalize(() => ctx.patchState({ saveAsDraftLoading: false })));
  }

  @Action(CancelCurrentAgreement)
  cancelCurrentAgreement(ctx: StateContext<AgreementBuilderStateModel>) {
    const { currentAgreement } = ctx.getState();

    if (!currentAgreement) throw new Error("Current agreement is not set.");

    return this.affiliationAgreementsService.cancelAffiliationAgreement({
      id: currentAgreement.id,
    });
  }

  @Action(SetInitialAgreementFields)
  setInitialAgreementFields(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SetInitialAgreementFields,
  ) {
    const { agreementDynamicFields } = ctx.getState();
    const { fields } = action.payload;

    ctx.patchState({
      agreementDynamicFields: {
        ...agreementDynamicFields,
        counterpartyOrganization: {
          name: fields.counterpartyOrganizationName ?? null,
          id: fields.counterpartyOrganizationId ?? null,
          googlePlaceId: null,
        },
      },
      allowCounterpartyUpdate: action.payload.fields.allowCounterpartyUpdate,
    });
  }

  @Action(SetImportedDocumentName)
  setImportedDocumentName(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SetImportedDocumentName,
  ) {
    ctx.patchState({
      importedDocumentName: action.payload.documentName,
      selectedTemplate: null,
    });
  }

  private getAffiliationAgreementBody(
    id: string | null,
    agreement: AgreementDynamicFieldsModel,
  ): AffiliationAgreement {
    const startDate = agreement.startDate
      ? new Date(agreement.startDate)
      : null;
    const endDate = agreement.endDate ? new Date(agreement.endDate) : null;

    return {
      id: id,
      name: agreement.agreementName!,
      disciplinesIds:
        agreement.disciplines
          ?.map((d) => d.id)
          .filter((id: number) => id !== BuiltInDisciplineId.Any) ?? [],
      applyForAnyDiscipline: !!agreement.disciplines?.some(
        (d) => d.id === BuiltInDisciplineId.Any,
      ),
      endDate: DateUtility.convertToDateOnlyString(endDate),
      noEndDate: agreement.noEndDate,
      startDate: DateUtility.convertToDateOnlyString(startDate),
      counterpartyOrganization:
        agreement.counterpartyOrganization satisfies AffiliationAgreementOrganization,
      externalPartnershipType: ExternalPartnershipType.None,
    } satisfies AffiliationAgreement;
  }

  private getContactBody(
    agreement: AgreementDynamicFieldsModel,
    message: string | null,
  ): AffiliationAgreementContact | null {
    if (agreement.counterpartyOrganization.id) return null;

    return {
      firstName: agreement.contactFirstName ?? null,
      lastName: agreement.contactLastName ?? null,
      email: agreement.contactEmail ?? null,
      phoneNumber: agreement.contactPhoneNumber ?? null,
      message: message ?? null,
    } satisfies AffiliationAgreementContact;
  }

  private getUpdatedAffiliationAgreementBody(
    agreement: AgreementDynamicFieldsModel,
    comment: string | null,
    submitChanges: boolean,
  ): ProvideChangesForAffiliationAgreementAffiliationAgreementBody {
    const endDate = agreement.endDate ? new Date(agreement.endDate) : null;

    return {
      name: agreement.agreementName!,
      changesComment: comment,
      disciplinesIds: agreement
        .disciplines!.map((d) => d.id)
        .filter((id: number) => id !== 0),
      endDate: DateUtility.convertToDateOnlyString(endDate),
      startDate: DateUtility.convertToDateOnlyString(
        new Date(agreement.startDate!),
      )!,
      submitChanges,
    } satisfies ProvideChangesForAffiliationAgreementAffiliationAgreementBody;
  }

  private loadAgreementTemplateOptions(
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    return this.affiliationAgreementsService
      .getAffiliationAgreementTemplates()
      .pipe(tap((response) => ctx.patchState({ templateOptions: response })));
  }

  private loadAgreementBuilderContent(
    agreementId: string,
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    const { agreementDynamicFields } = ctx.getState();

    return this.affiliationAgreementsService
      .getAffiliationAgreementBuilderContent({
        id: agreementId,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            agreementDynamicFields: {
              ...agreementDynamicFields,
              agreementName: response.agreementName,
              counterpartyOrganization: response.counterpartyOrganization,
              startDate: response.startDate,
              endDate: response.noEndDate ? undefined : response.endDate,
              noEndDate: response.noEndDate,
              disciplines: response.disciplines,
              applyForAnyDiscipline: response.anyDiscipline,
              contactFirstName: response.contact?.firstName,
              contactLastName: response.contact?.lastName,
              contactEmail: response.contact?.email,
              contactPhoneNumber: response.contact?.phoneNumber,
            },
            currentAgreement: {
              id: response.agreementId,
              name: response.agreementName,
              status: response.status,
              subStatus: response.subStatus,
              lastUpdatedAt: response.lastUpdatedAt,
              lastUpdatedByUser: response.lastUpdatedByUser,
            },
            builderContent: response.builderContent,
            selectedTemplate: response.template,
          });
        }),
      );
  }

  private generateUniqueTemplateName(
    agreementName: string,
    ctx: StateContext<AgreementBuilderStateModel>,
  ): string {
    const baseTemplateName = `${agreementName} Template`;

    const existingTemplateNames = ctx
      .getState()
      .templateOptions.map((template) => template.name)
      .filter((name) => name.startsWith(baseTemplateName));

    if (!existingTemplateNames.length) return baseTemplateName;

    const templateNumbers = existingTemplateNames.map((name) => {
      const trailingNumberRegex = /\((\d+)\)$/;
      const match = name.match(trailingNumberRegex);
      return match ? parseInt(match[1], 10) : 0;
    });

    const nextNumber = Math.max(0, ...templateNumbers) + 1;

    return `${baseTemplateName} (${nextNumber})`;
  }

  private getBuilderContentHtmlFile(
    fileName: string,
    ctx: StateContext<AgreementBuilderStateModel>,
    getTemplate: boolean = false,
  ): File {
    const { builderContent } = ctx.getState();

    if (!builderContent) throw new Error("Builder content is null or empty.");

    const content = getTemplate
      ? EditorDynamicFieldsUtility.replaceDynamicFieldsWithDefaults(
          builderContent,
        )
      : builderContent;

    return new File([content], `${fileName}.html`, {
      type: EDITOR_CONTENT_FILE_TYPE,
    });
  }
}
